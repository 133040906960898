/* Links in NavDropdown components should be white, bold text  */
.dropdown-link a {
  color: white;
  font-weight: bold;
}

/* dropdown-item should be black text */
.dropdown-item {
  color: #2D083F !important;
}

/* when dropdown-item is active, should have white text */
.dropdown-item.active {
  color: white !important;
}
