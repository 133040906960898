/* Scale the switch to be twice as large */
.large-switch .custom-control-label::before,
.large-switch .custom-control-label::after {
  transform: scale(2);
  transform-origin: center;
}

/* Adjust the label position */
.large-switch .custom-control-label {
  padding-left: 1rem;
}