.custom-button {
    background-color: #4d81f4;
    border-color: #4d81f4;
    color: white;
}

.custom-button:focus {
    background-color: #4d81f4;
    border-color: #4d81f4;
    color: white;
    box-shadow: none; /* Remove the default focus shadow */
}

.custom-button:disabled {
    background-color: #6c757d; /* Default disabled color */
    border-color: #6c757d; /* Default disabled color */
}